import * as d3 from "d3"

class K {
    static cr(tag: string, attributes?: Record<string, unknown>): K {
        const element = document.createElement(tag);
        const k = new K(element);
        if (attributes) k.attrs(attributes);
        return k;
    }

    static cra(parent: HTMLElement | K, tag: string, attributes?: Record<string, unknown>): K {
        const k = K.cr(tag, attributes);
        if (parent instanceof K) parent.el().appendChild(k.el());
        else parent.appendChild(k.el());
        return k;
    }

    private _element: HTMLElement;
    constructor(element: HTMLElement) {
        this._element = element;
    }
    el(): HTMLElement {
        return this._element;
    }
    attr(key: string, value: unknown): K {
        // //  special handling for class and style
        // if (key === "class"){
        //     if (value !== null && typeof value === "object" && !Array.isArray(value)){
        //         this._element.setAttribute(key, Object.keys(value).filter(k => (value as Record<string, unknown>)[k]).join(" "));
        //         return this;
        //     }
        // }
        // else if (key === "style"){
        //     if (value !== null && typeof value === "object" && !Array.isArray(value)){
        //         this._element.setAttribute(key, Object.keys(value).map(k => `${k}:${(value as Record<string, string>)[k]}`).join(";"));
        //         return this;
        //     }
        // }
        //  default handling
        this._element.setAttribute(key, value as string);
        return this;
    }
    attrs(attributes: Record<string, unknown>) : K{
        for (const key in attributes) {
            this.attr(key, attributes[key]);
        }
        return this;
    }
    text(str: string): K{
        this._element.textContent = str;
        return this;
    }
}

class MyPlotVisuals {
    showLine = true;
    showMarkers = false;
    showErrorArea = true;
    showErrorBars = false;
    showErrorLines = false;
    expertMode = false;
}

class Plot {
    traces: Trace[] = [];
    renderables: TraceRenderable[] = [];
    xMin = 0;
    xMax = 0;
    yMin = 0;
    yMax = 0;

    public prepare(plot: any){
        if (!plot) return;
        this.traces = [];
        for (const jsonTrace of plot.traces) {
            const handler = TraceHandler.getHandlerFor(jsonTrace);
            if (handler) {
                this.traces.push(handler.read(jsonTrace));
            }
        }

    }

    /**
     * in a second step the bounds of the traces are set with the help of the renderables,
     * which are also created here.
     * @param myD3
     */
    prepareBounds(myD3: MyD3){
        if (this.traces.length == 0){
            return;
        }
        const renderables: TraceRenderable[] = [];
        for (const trace of this.traces) {
            renderables.push(...trace.traceHandler.yieldRenderables(trace, myD3));
        }
        renderables.sort((a, b) => a.sort - b.sort);
        this.renderables = renderables;
        console.log("=== prepare bounds ===");
        for (const renderable of this.renderables){
            renderable.updateBounds();
        }

        this.xMin = Math.min(...this.traces.map(trace => trace.xMin));
        this.xMax = Math.max(...this.traces.map(trace => trace.xMax));
        this.yMin = Math.min(...this.traces.map(trace => trace.yMin), 0.0);
        this.yMax = Math.max(...this.traces.map(trace => trace.yMax), 2.0);
    }
}

class Trace {
    items: TraceData[] = [];
    settings: Record<string, any>;
    title = "";
    xMin = 0;
    xMax = 0;
    yMin = 0;
    yMax = 0;
    /** the hsva values from the trace settings. will be used as a multiplier for the saturation, brightness and alpha */
    hsva: number[] = [240, 1.0, 1.0, 1.0];
    colorFull = ``;
    colorFullAlpha10 = ``;
    colorFullAlpha25 = ``;
    colorDesaturated = ``;
    colorDark = ``;
    colorLight = ``;
    colorBarFill = ``;
    colorBarStroke = ``;
    colorText = ``;
    colorTextMuted = ``;
    /** the marker, that is being displayed when the mouse is over the plot */
    focusMarker: any;
    traceHandler: TraceHandler;
    keyX = "year";
    keyY = "trend";

    constructor(traceJson: any, traceHandler: TraceHandler) {
        this.traceHandler = traceHandler;
        this.settings = traceJson.settings || {};
        this.title = traceJson.title;
        this.keyX = this.settings.keyX || this.keyX;
        this.keyY = this.settings.keyY || this.keyY;
        this.applyStyle();
    }

    applyStyle(){
        const style = this.settings.style;
        if (style){
            this.hsva = style.hsva || this.hsva;
        }
        this.applyHsva(this.hsva[0], this.hsva[1], this.hsva[2], this.hsva[3]);
        // if (style){
        //     this.applyColors(style);
        // }
    }

    applyHsva(hue: number, saturation = 0.0, brightness = 1.0, alpha = 1.0) {
        const fn = (h: number, s: number, b: number, a: number) => {
            return `hsla(${h}, ${Math.round(saturation * s)}%, ${Math.round(brightness * b)}%, ${alpha * a})`
        }
        this.colorFull = fn(hue, 100, 50, 1.0);
        this.colorFullAlpha10 = fn(hue, 100, 50, 0.1);
        this.colorFullAlpha25 = fn(hue, 100, 50, 0.25);
        this.colorDesaturated = fn(hue, 30, 50, 1.0);
        this.colorDark = fn(hue, 100, 25, 1.0);
        this.colorLight = fn(hue, 100, 75, 1.0);
        this.colorBarFill = fn(hue, 70, 40, 0.5);
        this.colorBarStroke = fn(hue, 70, 20, 0.5);
        this.colorText = fn(hue, 100, 60, 1.0);
        this.colorTextMuted = fn(hue, 50, 60, 1.0);
    }

    // applyColors(style: Record<string, any>){
    //
    // }
}

class TraceData {
    data: any;

    constructor(data: any) {
        this.data = data;
    }
}

abstract class TraceHandler {
    private static HANDLERS: TraceHandler[] = [];
    private static getHandlers(): TraceHandler[]{
        if (this.HANDLERS.length == 0){
            this.HANDLERS.push(new RectangleHandler(), new TrendHandler());
        }
        return this.HANDLERS;
    }
    static getHandlerFor(jsonTrace: any): TraceHandler | undefined {
        const handlers = this.getHandlers();
        for (const handler of handlers){
            if (handler.canHandle(jsonTrace)){
                return handler;
            }
        }
        return undefined;
    }

    readItemsTabular(jsonTrace: any): TraceData[] {
        const table = jsonTrace.items;
        const keyX = jsonTrace.mode.x;
        const keyY = jsonTrace.mode.y;
        const header = table.h;
        const list: TraceData[] = [];
        for (const row of table.r){
            const vals: any = {};
            for (let idx = 0; idx<header.length; idx++){
                const key = header[idx];
                vals[key] = row[idx];
            }
            list.push(new TraceData(vals));
        }
        return list;
    }
    read(jsonTrace: Record<string, any>): Trace{
        const trace = this.doRead(jsonTrace);
        // prepare the renderables
        return trace;
    }
    abstract canHandle(jsonTrace: any): boolean;
    abstract doRead(jsonTrace: any): Trace;
    abstract yieldRenderables(trace: Trace, myD3: MyD3): TraceRenderable[];
}

class TrendHandler extends TraceHandler{
    canHandle(jsonTrace: any): boolean {
        return jsonTrace.settings?.type === "trend";
    }

    doRead(jsonTrace: any): Trace {
        const items = this.readItemsTabular(jsonTrace);
        const trace = new Trace(jsonTrace, this);
        trace.items = items;
        // calculate the basic bounds
        trace.xMin = Math.min(...trace.items.map(item => item.data[trace.keyX]));
        trace.xMax = Math.max(...trace.items.map(item => item.data[trace.keyX]));
        trace.yMin = Math.max(0.0, Math.min(...trace.items.map(item => item.data[trace.keyY]), 0.0));
        trace.yMax = Math.max(...trace.items.map(item => item.data[trace.keyY]), 2.0);
        return trace;
    }

    yieldRenderables(trace: Trace, myD3: MyD3): TraceRenderable[] {
        const settings = trace.settings;
        const list: TraceRenderable[] = [];
        if (settings.hideGraph !== true) {
            if (myD3.visuals.showErrorArea)
                list.push(new AreaRenderable(myD3, trace, 10));
            if (myD3.visuals.showLine) {
                list.push(new LineRenderable(myD3, trace, 20));
            }
        }
        if (settings?.hideFocus !== true) {
            list.push(new FocusMarkerRenderable(myD3, trace, 30));
            if (myD3.visuals.showMarkers) {
                list.push(new MarkersRenderable(myD3, trace, 21));
            }
            if (myD3.visuals.showErrorBars) {
                list.push(new ErrorBarsRenderable(myD3, trace, 19));
            }
            if (settings.keyRteLow && settings.keyRteHigh) {
                // better check again if the values are present!
                if (trace.items.length > 0 && trace.items[0].data[settings.keyRteLow] !== undefined && trace.items[0].data[settings.keyRteHigh] !== undefined) {
                    const r = new ErrorBarsRenderable(myD3, trace, 19);
                    r.mode = "lines";
                    r.keyValue = "rteVal";
                    r.keyLow = "rteLow";
                    r.keyHigh = "rteHigh";
                    list.push(r);
                }
            }
        }
        return list;
    }

}
class RectangleHandler extends TraceHandler{
    canHandle(jsonTrace: any): boolean {
        return jsonTrace.settings?.type === "rect";
    }
    doRead(jsonTrace: any): Trace {
        const trace = new Trace(jsonTrace, this);
        for (const item of jsonTrace.items) {
            trace.items.push(new TraceData(item));
        }
        return trace;
    }

    yieldRenderables(trace: Trace, myD3: MyD3): TraceRenderable[] {
        const r = new RectangleRenderable(myD3, trace, 0);
        return [r];
    }
}

abstract class TraceRenderable {
    myD3: MyD3;
    trace: Trace;
    sort: number;
    constructor(myD3: MyD3, trace: Trace, sort: number){
        this.myD3 = myD3;
        this.trace = trace;
        this.sort = sort;
    }
    abstract updateBounds(): void;
    abstract render(): void;
    static findMinMax(items: TraceData[], keys: string[], currentMin: number, currentMax: number){
        let min = currentMin || Infinity, max = currentMax || -Infinity;
        for (const item of items){
            for (const key of keys){
                const value = item.data[key];
                if (value === value) { // not NaN - more performant
                    if (value < min) min = value;
                    if (value > max) max = value;
                }
            }
        }
        return [min, max];
    }
    static findMinMax2(items: TraceData[], keysMin: string[], keysMax: string[], currentMin: number, currentMax: number){
        let min = currentMin || Infinity, max = currentMax || -Infinity;
        for (const item of items){
            for (const key of keysMin){
                const value = item.data[key];
                if (value === value) { // not NaN - more performant
                    if (value < min) min = value;
                }
            }
            for (const key of keysMax){
                const value = item.data[key];
                if (value === value) { // not NaN - more performant
                    if (value > max) max = value;
                }
            }
        }
        return [min, max];
    }
}

class LineRenderable extends TraceRenderable {
    keyX: string;
    keyY: string;

    constructor(myD3: MyD3, trace: Trace, sort: number) {
        super(myD3, trace, sort);
        this.keyX = trace.keyX;
        this.keyY = trace.keyY;
    }

    updateBounds() {
        const trace = this.trace;
        const x = TraceRenderable.findMinMax(trace.items, [trace.keyX], trace.xMin, trace.xMax);
        const y = TraceRenderable.findMinMax(trace.items, [this.keyY], trace.yMin, trace.yMax);
        trace.xMin = x[0];
        trace.xMax = x[1];
        trace.yMin = y[0];
        trace.yMax = y[1];
        // trace.xMin = Math.min(...trace.items.map(item => item.data[this.keyX]), trace.xMin);
        // trace.xMax = Math.max(...trace.items.map(item => item.data[this.keyX]), trace.xMax);
        // trace.yMin = Math.max(0.0, Math.min(...trace.items.map(item => item.data[this.keyY]), 0.0), trace.yMin);
        // trace.yMax = Math.max(...trace.items.map(item => item.data[this.keyY]), 2.0, trace.yMax);
    }

    render(): void {
        const trace = this.trace, svg = this.myD3.svg, x = this.myD3.axisX, y = this.myD3.axisY;
        const segments = MyD3.createSegmentsBySignificance(trace, this.myD3);

        for (const segment of segments){
            // extract the items of trace.items by x0 and x1
            const items = trace.items.slice(segment.x0, segment.x1);
            svg
                .append("path")
                .datum(items)
                .attr("fill", "none")
                .attr("stroke", segment.stroke)
                .attr("stroke-width", segment.strokeWidth || this.myD3.dlStrokeWidthMul * this.myD3.dlStrokeWidth)
                .attr("d", d3.line()
                    .x((d: any) => x(d.data[this.keyX]))
                    .y((d: any) => y(d.data[this.keyY]))
                );
        }
    }
}

class AreaRenderable extends TraceRenderable {
    keyX = "year";
    keyY0= "ciLow"
    keyY1 = "ciHigh";
    updateBounds() {
        const trace = this.trace;
        const x = TraceRenderable.findMinMax(trace.items, [trace.keyX], trace.xMin, trace.xMax);
        const y = TraceRenderable.findMinMax2(trace.items, [this.keyY0], [this.keyY1], trace.yMin, trace.yMax);
        trace.xMin = x[0];
        trace.xMax = x[1];
        trace.yMin = y[0];
        trace.yMax = y[1];
        // trace.xMin = Math.min(...trace.items.map(item => item.data[trace.keyX]), trace.xMin);
        // trace.xMax = Math.max(...trace.items.map(item => item.data[trace.keyX]), trace.xMax);
        // trace.yMin = Math.max(0.0, Math.min(...trace.items.map(item => item.data[this.keyY0]), 0.0), trace.yMin);
        // trace.yMax = Math.max(...trace.items.map(item => item.data[this.keyY1]), 2.0, trace.yMax);
        console.log("Area Renderable", x, y);
    }
    render(): void {
        const trace = this.trace, svg = this.myD3.svg, x = this.myD3.axisX, y = this.myD3.axisY;
        svg.append("path")
            .datum(trace.items)
            .attr("fill", trace.colorFullAlpha10)
            .attr("stroke", "none")
            .attr("d", d3.area()
                .x((d: any) => x(d.data[this.keyX]))
                .y0((d: any) => y(d.data[this.keyY0]))
                .y1((d: any) => y(d.data[this.keyY1]))
            );
    }
}

class FocusMarkerRenderable extends TraceRenderable {
    updateBounds() {
        // nothing to do here
    }

    render(): void {
        const trace = this.trace, svg = this.myD3.svg, myFocuses = [];
        if (trace.focusMarker) {
            // a quick fix to prevent the focus markers of the traces from being overriden
            return;
        }
        const colorFill = trace.colorFull;
        const myFocus = svg.append('circle')
            .style("fill", colorFill)
            .attr("stroke", colorFill)
            .attr("r", 5)
            .style("opacity", 0);
        myFocuses.push(myFocus);
        trace.focusMarker = myFocus; // store the focus marker in the trace
    }
}

class RectangleRenderable extends TraceRenderable {
    updateBounds() {
        const trace = this.trace;
        trace.xMin = Math.min(...trace.items.map(item => Math.min(item.data.x0, item.data.x1)), trace.xMin);
        trace.xMax = Math.max(...trace.items.map(item => Math.max(item.data.x0, item.data.x1)), trace.xMax);
        // console.log(`rect ${trace.xMin}/${trace.xMax} --- ${trace.yMin}/${trace.yMax}`)
    }

    render(): void {
        const myD3 = this.myD3;
        const svg = myD3.svg;
        const x = myD3.axisX, y = myD3.axisY;
        const y0 = y(myD3.preparedPlot.yMin);
        const y1 = y((myD3.preparedPlot.yMax - myD3.preparedPlot.yMin) * 0.05 + myD3.preparedPlot.yMin);
        for (const item of this.trace.items){
            const color = `hsla(${item.data.hue || 1}, 100%, 50%, 0.25)`;
            svg.append("rect")
                .attr("x", x(item.data.x0))
                .attr("y", y1)
                .attr("width", x(item.data.x1) - x(item.data.x0))
                .attr("height", y0 - y1)
                .attr("fill", color);
        }
    }
}

class ErrorBarsRenderable extends TraceRenderable {
    mode: "bars" | "lines" = "bars";
    keyLow = "ciLow";
    keyHigh = "ciHigh";
    keyValue = "trend";

    updateBounds() {
        const trace = this.trace;
        const x = TraceRenderable.findMinMax(trace.items, [trace.keyX], trace.xMin, trace.xMax);
        const y = TraceRenderable.findMinMax2(trace.items, [this.keyLow], [this.keyHigh], trace.yMin, trace.yMax);
        trace.xMin = x[0];
        trace.xMax = x[1];
        trace.yMin = y[0];
        trace.yMax = y[1];
        // trace.xMin = Math.min(...trace.items.map(item => item.data[trace.keyX]), trace.xMin);
        // trace.xMax = Math.max(...trace.items.map(item => item.data[trace.keyX]), trace.xMax);
        // trace.yMin = Math.max(0.0, Math.min(...trace.items.map(item => item.data[this.keyLow]), 0.0), trace.yMin);
        // trace.yMax = Math.max(...trace.items.map(item => item.data[this.keyHigh]), 2.0, trace.yMax);
        console.log(`ebars ${trace.xMin}/${trace.xMax} --- ${trace.yMin}/${trace.yMax}`)
    }

    render(): void {
        if (this.mode === "lines") this.renderLines();
        else this.renderBars();
    }

    renderBars(): void {
        const trace = this.trace, svg = this.myD3.svg, x = this.myD3.axisX, y = this.myD3.axisY
        const barWidth = x(this.myD3.preparedPlot.xMin + 1) * 0.5;
        svg.selectAll("mybar")
            .data(trace.items)
            .enter()
            .append("rect")
            .attr("x", (d: any) => x(d.data[trace.keyX]) - barWidth / 2)
            .attr("y", (d: any) => y(d.data[this.keyHigh]))
            .attr("width", barWidth)//x.bandwidth())
            .attr("height", (d: any) => y(d.data[this.keyLow]) - y(d.data[this.keyHigh]))
            .attr("fill", trace.colorBarFill)
            .attr("stroke", trace.colorBarStroke)
    }

    renderLines(): void {
        const trace = this.trace, svg = this.myD3.svg, x = this.myD3.axisX, y = this.myD3.axisY
        const stroke = trace.colorDark;
        const barWidth = x(this.myD3.preparedPlot.xMin + 1) * 0.5;
        const offsX = barWidth / 2;

        svg.selectAll("dots")
            .data(trace.items)
            .enter()
            .append("circle")
            .attr("cx", (d: any) => x(d.data[trace.keyX]))
            .attr("cy", (d: any) => y(d.data[this.keyValue]))
            .attr("r", 3)
            .attr("fill", trace.colorDark);
        // .attr("stroke", trace.colorFull);

        svg.selectAll("mybar")
            .data(trace.items)
            .enter()
            .append("line")
            .attr("x1", (d: any) => x(d.data[trace.keyX]))
            .attr("y1", (d: any) => y(d.data[this.keyLow]))
            .attr("x2", (d: any) => x(d.data[trace.keyX]))
            .attr("y2", (d: any) => y(d.data[this.keyHigh]))
            .attr("stroke", stroke)
            .style("stroke-width", this.myD3.dlStrokeWidthMul);
    }
}

class MarkersRenderable extends TraceRenderable {
    updateBounds() {
        // nothing to do here
    }

    render(): void {
        const trace = this.trace, svg = this.myD3.svg, x = this.myD3.axisX, y = this.myD3.axisY
        svg.selectAll("dots")
            .data(trace.items)
            .enter()
            .append("circle")
            .attr("cx", (d: any) => x(d.data[trace.keyX]))
            .attr("cy", (d: any) => y(d.data[trace.keyY]))
            .attr("r", 5)
            .attr("fill", '#fff')
            .attr("stroke", trace.colorFull);
    }
}

interface SignificanceSegment {
    /** a helper to describe the significance of a line segment */
    significance: number;
    x0: number;
    x1: number;
    stroke: string;
    strokeWidth: number;
}

interface MyD3CustomOptions {
    /**
     * the filename for the download
     */
    downloadName?: string;
    /**
     * introduced to support various sources of plots with varying site counts (for cc compare)
     * needs to be set explicitly to false to disable the gray text (last row of the tooltip)
     * when set to false, the nb of sites will be displayed after the values in the tooltip (for each trace)
     */
    ttShowSitesAtBottom?: boolean;
    expertMode?: boolean;
    title?: string;
    subtitle?: string;
}

class MyD3{
    plotId: string;
    customOptions?: MyD3CustomOptions;
    visuals: MyPlotVisuals = new MyPlotVisuals();
    data: any;
    vueTranslation: any;
    message: any;
    preparedPlot: Plot;
    svg: any;
    axisX: any;
    axisY: any;

    toolbarContainer: any;
    popupContainer: any;
    dlWidth = 1024;
    dlHeight = 768;
    dlFontSizeMul = 1.0;
    dlStrokeWidth = 3.0;
    dlStrokeWidthMul = 1.0;
    dlTitle = "";
    dlSubtitle = "";

    public static getPreparedPlot(data: any): Plot{
        const prepared = new Plot();
        prepared.prepare(data);
        return prepared;
    }

    public static createSegmentsBySignificance(trace: Trace, myD3?: MyD3): SignificanceSegment[]{
        const segments = [];
        let segment: any = undefined;
        const colorInc = trace.settings?.style?.colors?.increase || trace.colorDark;
        const colorDec = trace.settings?.style?.colors?.decrease || trace.colorDark;
        for (let idx = 0; idx < trace.items.length; idx++) {
            const item = trace.items[idx];
            const sig = item.data.significance;
            // const isSignificant = item.data?.significance === 1 || item.data?.significance === 2;
            const isNewSegment = segment === undefined || segment.significance != sig;
            if (isNewSegment){
                let stroke;
                if (myD3 && myD3.data.type === "TG"){
                    // stroke = sig === 1 ? trace.colorLight : (sig == 2 ? trace.colorDark : trace.colorFull);
                    stroke = sig === 0 ? trace.colorDesaturated : trace.colorFull;
                }
                else{
                    stroke = sig === 1 ? colorInc : (sig == 2 ? colorDec : trace.colorFull);
                }
                const strokeWidth=  (myD3?.dlStrokeWidth && myD3?.dlStrokeWidthMul)
                    ? myD3.dlStrokeWidth * myD3.dlStrokeWidthMul
                    : 1.0;
                segment = {
                    significance: sig,
                    stroke: stroke,
                    strokeWidth: strokeWidth,
                    x0: idx > 0 ? idx - 1 : 0,
                    x1: idx
                };
                segments.push(segment);
            }
            segment.x1 = idx + 1;
        }
        return segments;
    }

    constructor(plotId: string, data: any, customOptions: MyD3CustomOptions, vueTranslation: any) {
        this.plotId = plotId;
        this.data = data;
        this.customOptions = customOptions;
        this.vueTranslation = vueTranslation;
        this.preparedPlot = MyD3.getPreparedPlot(data);
        this.preparedPlot.prepareBounds(this);
    }

    private calcTextSize(preparedData: Plot) : {width: number, height: number} {
        //  hidden rendering to estimate the text size
        const mStr = "" + preparedData.yMax.toFixed(1) + ".0";
        const hiddenSVG = d3.select("body")
            .append("svg")
            .style("position", "absolute")
            .style("left", "-9999px")
            .style("top", "-9999px");
        const text = hiddenSVG.append("text")
            .text(mStr)
        // .attr("font-size", "12px");
        const bbox = text.node()?.getBBox() || new DOMRect();
        return {width: Math.round(bbox.width), height: Math.round(bbox.height)};
    }

    /**
     * Draw the plot
     */
    draw(options?: any){
        const drawToAlt = (options && options.svg);
        let svgRoot: any, width: number, height: number, margin;
        const preparedPlot = this.preparedPlot;

        let fontSize;
        {
            //  setup font size etc.
            let cw, ch; //store the w/h
            if (drawToAlt){
                cw = options.width;
                ch = options.height;
            }
            else{
                const plotElm: any = document.getElementById(this.plotId);
                cw = plotElm.clientWidth;
                ch = plotElm.clientHeight;
                this.dlWidth = cw;
                this.dlHeight = ch;
            }
            // Use a percentage of the smaller dimension for font size
            const fontSizeBase = ch * 0.025 * this.dlFontSizeMul;
            // fontSize = Math.max(12, fontSizeBase);
            fontSize = fontSizeBase;
        }


        if (drawToAlt){
            //  this means drawing to a hidden svg for image export or download
            //  TODO: this is untested after many code changes...
            const cw = options.width;
            const ch = options.height;
            const cs = Math.min(cw, ch);

            const m = Math.min(40, cs * 0.1);
            margin = {left: fontSize * 2 + 12, right: m, top: m, bottom: fontSize * 1 + 12};

            width = cw - margin.left - margin.right;
            height = ch - margin.top - margin.bottom;

            svgRoot = options.svg;
            svgRoot.attr(
                "viewBox",
                `0 0 ${width + margin.left + margin.right} ${
                    height + margin.top + margin.bottom}`);
        }
        else {
            const baseTextSize = this.calcTextSize(preparedPlot);
            //  regular drawing on website
            const plotElm: any = document.getElementById(this.plotId);
            margin = {left: fontSize * 2 + 12, right: baseTextSize.width, top: baseTextSize.height, bottom: fontSize * 1 + 12};

            plotElm.innerHTML = '';
            width = plotElm.clientWidth - margin.left - margin.right;
            height = plotElm.clientHeight - margin.top - margin.bottom;

            svgRoot = d3.select('#' + this.plotId).append("svg").attr("viewBox", `0 0 ${plotElm.clientWidth} ${plotElm.clientHeight}`);
        }
        svgRoot.append("rect")
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("fill", "white");
        const svg = svgRoot.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        this.svg = svg;

        // first add title / subtitle
        const fsTitle = fontSize * 2;
        if (this.dlTitle){
            svg.append("text")
                .attr("x", width / 2)
                .attr("y", 0)
                .attr("text-anchor", "middle")
                .style("font-size", fsTitle + "px")
                .style("font-family", "sans-serif")
                .text(this.dlTitle);
        }
        if (this.dlSubtitle){
            svg.append("text")
                .attr("x", width / 2)
                .attr("y", fsTitle)
                .attr("text-anchor", "middle")
                .style("font-size", (fontSize * 1.5) + "px")
                .style("font-family", "sans-serif")
                .style("fill", "#999")
                .text(this.dlSubtitle);
        }


        // Add X axis --> it is a date format
        const x = d3.scaleLinear()
            .domain([preparedPlot.xMin, preparedPlot.xMax])
            .range([ 0, width ]);
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x)
                .tickFormat(function (d:any, i:any){
                    return d;
                })
                // Control tick density to prevent overlap
                // .ticks(Math.max(5, Math.floor(width / (fontSize * 6))))
                .ticks(Math.max(5, Math.floor(width / (fontSize * 5))))
            )
            .selectAll("text")
            .style("font-size", fontSize + "px");


        // Add Y axis
        const y = d3.scaleLinear()
            .domain([preparedPlot.yMin, preparedPlot.yMax])
            .range([ height, 0 ]);
        svg.append("g")
            .call(d3.axisLeft(y)
                // Control tick density to prevent overlap
                .ticks(Math.max(5, Math.floor(height / (fontSize * 4))))
            )
            .selectAll("text")
            .style("font-size", fontSize + "px");

        this.axisX = x;
        this.axisY = y;

        for (const renderable of this.preparedPlot.renderables){
            renderable.render();
        }

        // horizontal line at 1.0
        svg.append('line')
            .style("stroke", "#999")
            .style("stroke-width", 1.0)// * this.dlStrokeWidthMul)
            .attr("x1", 0)
            .attr("y1", y(1.0))
            .attr("x2", width)
            .attr("y2", y(1.0))
            .style("stroke-dasharray", ("3, 3"));

        //MYLINE (the lines that move with the mouse)
        const myLine = svg.append('line')
            .style("stroke", "#999")
            .style("stroke-width", 1.0 * this.dlStrokeWidthMul)
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", 0)
            .attr("y2", height)
            .style("opacity", 0);
        const myLine2 = svg.append('line')
            .style("stroke", "#999")
            .style("stroke-width", 1.0 * this.dlStrokeWidthMul)
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", width)
            .attr("y2", 0)
            .style("opacity", 0);
        const myLine2Text = svg.append("text")
            .attr("x", x(preparedPlot.xMin) + 3) // x-coordinate of the text
            .attr("y", y(preparedPlot.yMin)) // y-coordinate of the text
            .attr("text-anchor", "start") // alignment of the text
            .attr("fill", "#999").attr("font-size", "12px")
            .text(""); // the text content

        const allFocusMarkers: any[] = [];
        for (const trace of preparedPlot.traces) {
            if (trace.focusMarker) allFocusMarkers.push(trace.focusMarker);
        }


        const myTooltip = svg.append("foreignObject")
            .attr("width", width) // we want to use the whole svg area
            .attr("height", height) // we want to use the whole svg area
            .append("xhtml:body")
            .style("opacity", 0)
            .style("position", "absolute")
            .style("top", "0px")
            .style("left", "0px");

        if (this.message){
            const cx = width/2;
            const cy = height/2;

            const rw = cx;
            const rh = 60;
            const myRect = svg.append("rect")
                .attr("width", "" + rw + "px")
                .attr("height", "" + rh + "px")
                .attr("x", cx - rw/2)
                .attr("y", cy - rh/2)
                .attr("fill", "rgba(248,215,218,0.75)")
                .attr("stroke", "#842029");

            const myTest = svg
                .append('text')
                .attr("x", cx)
                .attr("y", cy)
                .attr("text-anchor", "middle")
                .attr("dominant-baseline", "central")
                .style("fill", "#842029")
                .text(this.message.text);
        }



        //THE mouse reactive rect on top
        const myRect = svg.append("rect")
            .style("pointer-events", "all")
            .attr("width", "" + width + "px")
            .attr("height", "" + height + "px")
            .attr("fill", "none");





        const hideAndShow = [myLine, myLine2, myLine2Text, myTooltip];
        for (const f of allFocusMarkers) hideAndShow.push(f);
        const vueTranslation = this.vueTranslation;
        myRect.on("mouseover", (e:any) => {
            for (const idx in hideAndShow){
                hideAndShow[idx].style("opacity", 1);
            }
            myTooltip.style("opacity", 0.85);
        })
            .on("mousemove", (e:any) => {
                const mouse = d3.pointer(e);
                const mx = mouse[0];
                const my = mouse[1];
                const x0 = x.invert(mx);

                myLine.attr("x1", mx).attr("x2", mx);
                myLine2.attr("y1", my).attr("y2", my);
                myLine2Text.attr("y", my - 3).text("" + y.invert(my).toFixed(2));

                let html = `<div class="fs-6" style="border: 1px solid #ccc; padding: 3px; background-color: #fff; border-radius: 5px; text-align: center; white-space: nowrap;">`;
                let markerX = -1, markerY = -1, nbSites = -1;
                let isFirst = true;
                for (let idx=0; idx<preparedPlot.traces.length; idx++) {
                    const trace = preparedPlot.traces[idx];
                    if (trace.settings?.hideFocus) continue;
                    // get the index of the closest x to the mouse
                    // This allows to find the closest X index of the mouse:
                    const bisect = d3.bisector(function(d:any) { return d.data[trace.keyX]; }).center;
                    const i = bisect(trace.items, x0, 0);
                    // get the corresponding data for this index
                    const selectedData = trace.items[i];
                    // get the x and y values for coords
                    const selX = selectedData.data[trace.keyX];
                    const selY = selectedData.data[trace.keyY];
                    const pixelX = x(selX);
                    const pixelY = y(selY);
                    if (isFirst) {
                        // use the first trace to set the marker position, which is used for the tooltip
                        markerX = pixelX;
                        markerY = pixelY;
                    }
                    // adjust the position of the focus circle
                    if (trace.focusMarker) {
                        trace.focusMarker.attr("cx", pixelX).attr("cy", pixelY);
                    }
                    // modify the tooltip html
                    {
                        if (isFirst) html += `<div style="font-weight: bold">${selX}</div>`;
                        html += `<div style="color: ${trace.colorText};">`;
                        if (this.data.type === 'TG')
                            html += `${trace.title} `;
                        html += `${selY.toFixed(2)} <span style="color: ${trace.colorTextMuted}">(${selectedData.data.ciLow.toFixed(2)} - ${selectedData.data.ciHigh.toFixed(2)})</span>`;
                        if (selectedData.data.nbSites !== undefined) {
                            if (nbSites < 0) {
                                nbSites = selectedData.data.nbSites;
                            }
                            if (this.customOptions?.ttShowSitesAtBottom === false) {
                                html += `, ${vueTranslation('trends.nbSites', {n: selectedData.data.nbSites})}`
                            }
                        }
                        html += '</div>';
                    }
                    isFirst = false;
                }

                // finish the tooltip
                if (this.customOptions?.ttShowSitesAtBottom !== false) {
                    if (nbSites >= 0) html += '<div style="color: #999;">' + vueTranslation('trends.nbSites', {n: nbSites}) + '</div>';
                    html += "</div>";
                }
                myTooltip.html(html);

                // position the tooltip
                const markerMargin = 10;
                const bounds = myTooltip.node().getBoundingClientRect();
                let left = markerX - bounds.width/2;
                let top = markerY - bounds.height - markerMargin;
                if (left < 0) left = 0;
                else if (left + bounds.width > width) left = width - bounds.width;
                if (top < 0) top = 0;
                else if (top + bounds.height > height) top = height - bounds.height;
                //check if the tooltip covers the marker
                if (top < markerY && top + bounds.height > markerY){
                    top = markerY + markerMargin;
                    //are we still in bounds of the svg?
                    if (top + bounds.height > height) top = height - bounds.height;
                }
                myTooltip
                    .style("left", left + "px")
                    .style("top", top + "px")

            })
            .on("mouseleave", (e:any) => {
                for (const idx in hideAndShow){
                    hideAndShow[idx].style("opacity", 0);
                }
            })
        if (!drawToAlt)
            this.addUI(svgRoot, svg, width, height);
    }

    addUI(svgRoot: any, svg: any, width: number, height: number){
        const rootContainer = document.getElementById(this.plotId);
        if (!rootContainer) return;
        if (this.toolbarContainer) return;

        this.toolbarContainer = K.cra(rootContainer, "div").attr("class", "position-absolute top-0 end-0 m-2 text-end m-0").el();

        const imageButton = K.cra(this.toolbarContainer, "button").attr("class", "btn btn-sm btn-outline-secondary").el();
        imageButton.innerHTML = '<svg width="16" height="16"><use xlink:href="#save"/></svg>';
        imageButton.onclick = () => {
            if (! this.popupContainer) return;
            this.buildDownloadPopup();
        }

        this.popupContainer = K.cra(rootContainer, "div")
            .attr("class", "card position-absolute top-0 end-0 m-2 p-3")
            .el();
        this.popupContainer.style.display = 'none';
        this.popupContainer.style.zIndex = 1;
    }

    buildDownloadPopup(){
        const $t = this.vueTranslation;
        const closeButton = K.cr("button")
            .attr("type", "button")
            .attr("class", "btn-close position-absolute top-0 end-0 m-3")
            .attr("aria-label", "Close")
            .el();
        // Add an event listener to the close button to hide the popupContainer when the button is clicked
        closeButton.addEventListener("click", () => {
            this.popupContainer.style.display = 'none';
        });


        const headerMain = K.cr("h5").text($t('trends.image.title')).el();
        const textMain = K.cr("p").attr("class", "text-muted").text($t('trends.image.text')).el();

        const resDiv = K.cr("div").attr("class", "d-flex gap-2").el();
        const resHeader = K.cr("h6").text($t('trends.image.resolution')).el();

        const inputW = K.cra(resDiv, "input")
            .attr("type", "number")
            .attr("placeholder", "W")
            .attr("class", "").attr("value", "" + this.dlWidth).el() as HTMLInputElement;

        const inputH = K.cra(resDiv, "input")
            .attr("type", "number")
            .attr("placeholder", "H")
            .attr("class", "")
            .attr("value", "" + this.dlHeight).el() as HTMLInputElement;


        //  additional settings
        // const optsHeader = document.createElement("h6");
        // optsHeader.textContent = $t('trends.image.options');
        const optsDiv = K.cr("div").attr("class", "d-flex gap-3 mb-3").el();

        const optsDiv2 = K.cr("div").attr("class", "d-flex gap-3 mb-3").el();

        //  stroke width
        const labelStrokeWidth = K.cra(optsDiv, "label", {"for": "strokeWidth"}).text($t('trends.image.strokeWidthMain')).el();
        const inputStrokeWidth = K.cra(optsDiv, "input", {
            "id": "strokeWidth",
            "type": "number",
            "step": "0.05",
            "min": "0.5",
            "max": "20",
            "class": "",
            "value": "" + this.dlStrokeWidth
        }).el() as HTMLInputElement;

        //  stroke width mul
        const labelStrokeWidthMul = K.cra(optsDiv, "label", {"for": "strokeWidthMul"}).text($t('trends.image.strokeWidthMul')).el();
        const inputStrokeWidthMul = K.cra(optsDiv, "input", {
            "id": "strokeWidthMul",
            "type": "number",
            "step": "0.05",
            "min": "0.25",
            "max": "3",
            "class": "",
            "value": "" + this.dlStrokeWidthMul
        }).el() as HTMLInputElement;

        //  font size mul
        const labelFontSizeMul = K.cra(optsDiv2, "label", {"for": "fontSizeMul"}).text($t('trends.image.fontSizeMul')).el();
        const inputFontSizeMul = K.cra(optsDiv2, "input", {
            "id": "fontSizeMul",
            "type": "number",
            "step": "0.05",
            "min": "0.5",
            "max": "3",
            "class": "",
            "value": "" + this.dlFontSizeMul
        }).el() as HTMLInputElement;

        //  header titles and labels for the plot
        const labelsDiv = K.cr("div");
        const labelsRow1 = K.cra(labelsDiv,"div", {class: "d-flex gap-3 mb-3"});
        const labelsSpan1 = K.cra(labelsRow1,"span").text($t('trends.image.title'));
        const inputTitle = K.cra(labelsRow1,"input", {type: "text", class: "form-control", value: this.customOptions?.title || ""}).el() as HTMLInputElement;
        const labelsRow2 = K.cra(labelsDiv,"div", {class: "d-flex gap-3 mb-3"});
        const labelsSpan2 = K.cra(labelsRow2,"span").text($t('trends.image.subtitle'));
        const inputSubtitle = K.cra(labelsRow2,"input", {type: "text", class: "form-control", value: this.customOptions?.subtitle || ""}).el() as HTMLInputElement;


        //  now add the buttons for the different file formats
        const formatsHeader = document.createElement("h6");
        formatsHeader.textContent = $t('trends.image.save');

        const formatsRow = document.createElement("div");
        formatsRow.setAttribute("class", "btn-group");
        const formats = ["SVG", "PNG", "JPEG", "WEBP"];
        for (const format of formats){
            const btn = document.createElement("button");
            btn.setAttribute("class", "btn btn-sm btn-secondary");
            btn.textContent = format;
            btn.addEventListener("click", () => {
                try {
                    this.dlWidth = parseInt(inputW.value);
                    this.dlHeight = parseInt(inputH.value);
                    this.dlFontSizeMul = Math.max(0.5, Math.min(3.0, parseFloat(inputFontSizeMul.value)));
                    this.dlStrokeWidth = Math.max(0.5, Math.min(20.0, parseFloat(inputStrokeWidth.value)));
                    this.dlStrokeWidthMul = Math.max(0.25, Math.min(3.0, parseFloat(inputStrokeWidthMul.value)));
                    inputFontSizeMul.value = this.dlFontSizeMul.toString(); //  update the value in case it was out of bounds
                    inputStrokeWidth.value = this.dlStrokeWidth.toString(); //  update the value in case it was out of bounds
                    inputStrokeWidthMul.value = this.dlStrokeWidthMul.toString(); //  update the value in case it was out of bounds
                    this.dlTitle = inputTitle.value;
                    this.dlSubtitle = inputSubtitle.value;
                    const options: any = {
                        type: format.toLowerCase(),
                        width: this.dlWidth,
                        height: this.dlHeight,
                        name: this.customOptions?.downloadName || "plot"
                    };
                    this.download(options);
                }
                catch (e){
                    console.error("Could not parse width or height");
                }
            });
            formatsRow.appendChild(btn);
        }

        const errorText = document.createElement("div");
        errorText.setAttribute("class", "text-danger");


        // add a validation for the resolution
        const isValidResolution = () => {
            const w = parseInt(inputW.value);
            const h = parseInt(inputH.value);
            if (isNaN(w) || isNaN(h)){
                return false;
            }
            if (w < 400 || h < 300){
                return false;
            }
            if (w > 2048 || h > 2048){
                return false;
            }
            return true;
        };
        const resCheck = (event: Event) => {
            const valid = isValidResolution();
            if (valid){
                this.dlWidth = parseInt(inputW.value);
                this.dlHeight = parseInt(inputH.value);
            }
            else {
                errorText.textContent = $t('trends.image.invalidResolution');
            }
            formatsHeader.style.display = valid ? 'block' : 'none';
            formatsRow.style.display = valid ? 'block' : 'none';
            errorText.style.display = valid ? 'none' : 'block';

        }
        inputW.addEventListener('input', resCheck);
        inputH.addEventListener('input', resCheck);


        while (this.popupContainer.firstChild) {
            this.popupContainer.removeChild(this.popupContainer.firstChild);
        }
        this.popupContainer.appendChild(closeButton);
        this.popupContainer.appendChild(headerMain);
        this.popupContainer.appendChild(textMain);
        this.popupContainer.appendChild(document.createElement("hr"));
        this.popupContainer.appendChild(resHeader);
        this.popupContainer.appendChild(resDiv);
        if (this.customOptions?.expertMode) {
            this.popupContainer.appendChild(document.createElement("hr"));
            // this.popupContainer.appendChild(optsHeader);
            this.popupContainer.appendChild(optsDiv);
            this.popupContainer.appendChild(optsDiv2);
            this.popupContainer.appendChild(document.createElement("hr"));
            this.popupContainer.appendChild(labelsDiv.el());
        }
        this.popupContainer.appendChild(document.createElement("hr"));
        this.popupContainer.appendChild(formatsHeader);
        this.popupContainer.appendChild(formatsRow);
        this.popupContainer.appendChild(errorText);
        this.popupContainer.style.display = 'block';

    }

    /**
     * Download the plot as an image
     * @param type The type of image to download
     */
    download(options: any) {
        if (options.type === 'svg'){
            this.toSvg(options);
        }
        else{
            this.toImage(options);
        }
    }


    /**
     * For downloading the plot as an image, we first create a SVG image as URL (which can be the src of an image)
     * @private
     */
    private svgInit(options: any) : any{
        let svg;
        if (options.width){
            //svg = d3.select('#' + this.plotId).select("svg");
            svg = d3.create("svg").attr("width", options.width).attr("height", options.height);
            options.svg = svg;
            this.draw(options);
        }
        else{
            svg = d3.select('#' + this.plotId).select("svg");
        }
        const svgNode = svg.node() as SVGSVGElement;
        if (!svgNode){
            throw new Error("Could not create SVG Node");
        }
        const svgString = new XMLSerializer().serializeToString(svgNode);
        const blob = new Blob([svgString], {type: "image/svg+xml"});
        const DOMURL = window.URL || window.webkitURL || window;
        const url = DOMURL.createObjectURL(blob);
        console.log("Created SVG Image with len " + url.length + " and svg XML len = " + svgString.length);
        return {
            svg: svg,
            url: url
        }
    }

    /**
     * Creates an SVG image from the plot
     * @private
     */
    private createSvgImage(options: any) {
        const data = this.svgInit(options);
        data.img = new Image();
        data.img.src = data.url;
        return data;
    }

    /**
     * Creates a download link and clicks it
     * @param url
     * @param name
     * @private
     */
    private downloadClick(url: string, name: string){
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = name;
        downloadLink.click();

        const DOMURL = window.URL || window.webkitURL || window;
        DOMURL.revokeObjectURL(url);
    }

    /**
     * Downloads the plot as an image of the given type
     * @param type the type of image to download (png, jpeg, ...)
     * @private
     */
    private toImage(options: any) {
        const data = this.createSvgImage(options);
        let svgSize: any;
        if (options.width){
            svgSize = {width: options.width, height: options.height};
        }
        else{
            svgSize = data.svg.node().getBoundingClientRect();
        }
        console.log("SVG size = " + svgSize.width + " x " + svgSize.height);
        const dl = this.downloadClick;
        data.img.onload = function() {
            const canvas = document.createElement('canvas');
            canvas.width = svgSize.width;
            canvas.height = svgSize.height;
            data.img.width = canvas.width;
            data.img.height = canvas.height;
            const ctx = canvas.getContext('2d');
            if (! ctx){
                return;
            }
            ctx.drawImage(data.img, 0, 0);
            const imgURI = canvas
                .toDataURL("image/" + options.type);
            // .replace("image/png", "image/octet-stream");
            dl(imgURI, `${options.name}.${options.type}`);
        }
    }

    /**
     * Downloads the plot as an SVG image
     * @private
     */
    private toSvg(options: any) {
        const data = this.svgInit(options);
        this.downloadClick(data.url, `${options.name}.svg`);
    }
}

export {MyD3, MyPlotVisuals, MyD3CustomOptions}