import {defineStore} from 'pinia'

export const useTrendsStore = defineStore('trends', {
  state: () => ({
    com: null,
    user: null,
    /**
     * the plot settings object manages all the sane possible choices and the current settings for the trends page
     */
    plotSettings: null,

    speciesDict: {},

    // /**
    //  * all the column definitions, for descriptors etc.
    //  */
    // columnDefs: null,
    // /**
    //  * a map holding all the species with the id (Mmyo, Mnat ...) as key
    //  */
    // species: null,
    // /**
    //  * all the statuses
    //  */
    // statuses: null,
    // obervationTypes: null,
    //
    // /**
    //  * holds all the suppliers (sources)
    //  */
    // suppliers: null,
    // /**
    //  * for plot options; holds all the sane possibilities for the selectors
    //  */
    // possibleChoices: null,
    // plotOptions: null,
    // sourceFiles: null,
    // /**
    //  * a summary of all the source files (summed up values) stored in the structure of an actual source file.
    //  */
    // sumupSourceFile: null,
    // locations: null,
  }),
})
