<template>
  <div>
    <div class="row">
      <div class="col-12 mt-2" style="z-index: 1;">
        <ul class="nav">
          <li class="nav-item dropdown bg-light border border-bottom-0 rounded-top">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" id="nlSpecies">
              <i v-if="settings.species">{{ settings.species.latin }}</i>
              <strong v-else class="fw-bold">
                <svg width="20" height="20" class="flex-shrink-0 me-2"><use xlink:href="#bi-1-circle"/></svg>
                {{ $t('plotOptions.selectSpecies') }}
              </strong>
              <!--              <div v-else class="fw-bold text-danger d-flex flex-row align-items-center justify-content-center">-->
              <!--                <svg width="20" height="20" class="flex-shrink-0 me-2"><use xlink:href="#bi-1-circle"/></svg>-->
              <!--                {{ $t('plotOptions.selectSpecies') }}-->
              <!--              </div>-->
            </a>
            <ul class="my-dropdown dropdown-menu cols-scrollable">
              <li v-for="(spec, key) in settings.availableCountTypes" :key="key" class="dropdown-item my-dropdown-item"
                  @click="selectSpecies(spec)"
                  :class="{'active': settings.species && settings.species.id === spec.id}">
                <i>{{spec.latin}}</i> <span class="text-muted">({{capitalizeAll(spec.name)}})</span>
              </li>
            </ul>
          </li>
          <!--          <li class="nav-item dropdown">-->
          <!--            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button">{{ (settings.observationType) ? settings.observationType.name : $t('plotOptions.selectObsType') }}</a>-->
          <!--            <ul class="my-dropdown dropdown-menu cols-scrollable">-->
          <!--              <li v-for="(t, key) in settings.availableObservationTypes" :key="key" class="dropdown-item my-dropdown-item"-->
          <!--                  @click="selectObservationType(t)"-->
          <!--                  :class="{'active': settings.observationType && settings.observationType.id === t.id}">-->
          <!--                {{ t.name }}-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" :class="{'disabled': !settings.species}" data-bs-toggle="dropdown" href="#" role="button" id="nlRegion">
              <svg v-if="!settings.species" width="20" height="20" class="flex-shrink-0 me-2"><use xlink:href="#bi-2-circle"/></svg>
              {{ (settings.selectedRegion) ?
                    settings.selectedRegion.type === "b" ? capitalizeAll(settings.selectedRegion.name)
                                                     : settings.selectedRegion.name
                : $t('plotOptions.selectRegion') }}
            </a>
            <ul class="my-dropdown dropdown-menu cols-scrollable">
              <li class="dropdown-item my-dropdown-item" @click="selectGeo1(settings.allRegions)" :class="{'active': settings.selectedRegion && settings.selectedRegion.id === settings.allRegions.id}">{{ settings.allRegions.name }}</li>
<!--              <li class="dropdown-item my-dropdown-item" @click="selectGeo1(settings.mapRegion)" :class="{'active': settings.selectedRegion && settings.selectedRegion.id === settings.mapRegion.id}">{{ settings.mapRegion.name }}</li>-->
<!--              <li v-if="settings.selectedRegion && settings.selectedRegion.id === settings.mapRegion.id" class="dropdown-item my-dropdown-item active" @click="selectGeo1(settings.mapRegion)">{{ settings.mapRegion.name }}</li>-->
              <hr v-if="settings.availableBiogeo?.length > 0">
              <li v-for="(geo, key) in settings.availableBiogeo" :key="'bg' + key" class="dropdown-item my-dropdown-item"
                  @click="selectBioGeo(geo)"
                  :class="{'active': geo.id === settings.selectedRegion.id}">
                {{ capitalizeAll(geo.name) }}
              </li>
              <hr v-if="settings.availableGeo1?.length > 0">
              <li v-for="(geo, key) in settings.availableGeo1" :key="key" class="dropdown-item my-dropdown-item"
                  @click="selectGeo1(geo)"
                  :class="{'active': geo.id === settings.selectedRegion.id}">
                {{ geo.name }}
              </li>
              <hr v-if="settings.avalaibleGeoAdm?.length > 1">
              <li v-for="(geo, key) in settings.avalaibleGeoAdm" :key="'ga' + key" class="dropdown-item my-dropdown-item"
                  @click="selectGeoAdm(geo)"
                  :class="{'active': geo.id === settings.selectedRegion.id}">
                {{ geo.name }}
              </li>
            </ul>
          </li>

          <div class="nav-item d-flex flex-row align-items-center text-muted ms-3 me-1">
            <div id="nlTimespan1">
              <svg v-if="!settings.species" width="20" height="20" class="flex-shrink-0 me-2"><use xlink:href="#bi-3-circle"/></svg>
              <span>{{ $t('plotOptions.timespan') }}</span>
            </div>
          </div>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" :class="{'disabled': !settings.species}" data-bs-toggle="dropdown" href="#" role="button" id="nlTimespan2">{{ settings.yearStart }}</a>
            <ul class="my-dropdown dropdown-menu cols-scrollable">
              <li v-for="(y, index) in settings.yearsMin()" :key="index" class="dropdown-item my-dropdown-item"
                  @click="selectYearStart(y)"
                  :class="{'active': settings.yearStart && settings.yearStart === y}">
                {{ y }}
              </li>
            </ul>
          </li>
          <div class="nav-item d-flex flex-row align-items-center text-muted mx-1">
            <span id="nlTimespan3">{{ $t('plotOptions.timespanTo') }}</span>
          </div>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" :class="{'disabled': !settings.species}" data-bs-toggle="dropdown" href="#" role="button" id="nlTimespan4">{{ settings.yearEnd === 2030 ? 'Max.' : settings.yearEnd }}</a>
            <ul class="my-dropdown dropdown-menu cols-scrollable">
              <li v-for="(y, index) in settings.yearsMax()" :key="index" class="dropdown-item my-dropdown-item"
                  @click="selectYearEnd(y)"
                  :class="{'active': settings.yearEnd && settings.yearEnd === y}">
                {{ y === 2030 ? 'Max.' : y }}
              </li>
            </ul>
          </li>
          <a v-if="settings.canUseMoreOptions()" class="nav-link" data-bs-toggle="collapse" href="#collapseMoreOptions" role="button">{{$t('plotOptions.moreOptions')}}</a>
        </ul>
      </div>
    </div>

    <div v-if="settings.canUseMoreOptions()" class="collapse container mb-4 card shadow-lg" id="collapseMoreOptions" style="position: absolute; z-index: 1001;">
      <div class="row">
        <div class="col m-1">
          <h5>Visuals</h5>
          <!-- General plot / graph settings -->
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cbShowLine" v-model="plotVisuals.showLine">
            <label class="form-check-label" for="cbShowLine">
              {{ $t("plotOptions.more.showLine") }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cbShowMarkers" v-model="plotVisuals.showMarkers">
            <label class="form-check-label" for="cbShowMarkers">
              {{ $t("plotOptions.more.showMarkers") }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cbErrorArea" v-model="plotVisuals.showErrorArea">
            <label class="form-check-label" for="cbErrorArea">
              {{ $t("plotOptions.more.showErrorArea") }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cbErrorBars" v-model="plotVisuals.showErrorBars">
            <label class="form-check-label" for="cbErrorBars">
              {{ $t("plotOptions.more.showErrorBars") }}
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="cbErrorLines" v-model="plotVisuals.showErrorLines">
            <label class="form-check-label" for="cbErrorLines">
              {{ $t("plotOptions.more.showErrorLines") }}
            </label>
          </div>
          <div v-if="settings.plotMode === 'CLASSIC'">
            <h5>{{ $t("plotOptions.more.date") }}</h5>
            <div>
              <div class="form-check mb-2">
                <input class="form-check-input" type="checkbox" value="" id="cbLimitDate" v-model="settings.filterDate">
                <label class="form-check-label" for="cbLimitDate">
                  {{ $t("plotOptions.more.dateLimit") }}
                </label>
              </div>
              <div v-if="settings.filterDate">
                <div class="input-group mb-2">
                  <div class="input-group-text">{{ $t("plotOptions.more.monthFrom") }}</div>
                  <select class="form-select form-select-sm" v-model="settings.filterDateMonthStart">
                    <option v-for="month in 12" :value="month" :key="'mfrom' + month">{{month}}</option>
                  </select>
                  <div class="input-group-text">{{ $t("plotOptions.more.monthTo") }}</div>
                  <select class="form-select form-select-sm" v-model="settings.filterDateMonthEnd">
                    <option v-for="month in 12" :value="month" :key="'mto' + month">{{month}}</option>
                  </select>
                </div>
                <div class="form-check mb-2">
                  <input class="form-check-input" type="checkbox" value="" id="cbLimitDateNulls" v-model="settings.filterDateIncludeNulls">
                  <label class="form-check-label" for="cbLimitDateNulls">
                    {{ $t("plotOptions.more.dateIncludeMissingExact") }}
                  </label>
                </div>
              </div>
            </div>

            <h5 class="mt-4">{{ $t("plotOptions.more.experimental") }}</h5>
            <div>
              <div class="form-check mb-2">
                <input class="form-check-input" type="checkbox" value="" id="cbYearGaps" v-model="settings.fillYearGaps">
                <label class="form-check-label" for="cbYearGaps">
                  {{ $t("plotOptions.more.fillYearGaps") }}
                </label>
              </div>
              <div class="form-check mb-2">
                <input class="form-control" type="number" id="inputMinCounts" v-model="settings.minCountsPerSite" min="0" max="50" step="1">
                <label class="form-label" for="inputMinCounts">
                  {{ $t("plotOptions.more.minCounts") }}
                </label>
              </div>
            </div>
          </div>


        </div>
        <div class="col m-1">
          <h5>{{ $t("plotOptions.more.plot") }}</h5>
          <div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" name="plotMode" type="radio" id="cbModeClassic" value="CLASSIC" v-model="settings.plotMode" @click="changePlotMode('CLASSIC')">
              <label class="form-check-label" for="cbModeClassic">Classic</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" name="plotMode" type="radio" id="cbModeCC" value="CC" v-model="settings.plotMode" @click="changePlotMode('CC')">
              <label class="form-check-label" for="cbModeCC">CC</label>
            </div>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" name="plotType" type="radio" id="cbTrim" value="TRIM" v-model="settings.plotModel">
            <label class="form-check-label" for="cbTrim">TRIM</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" name="plotType" type="radio" id="cbGam" value="GAM" v-model="settings.plotModel">
            <label class="form-check-label" for="cbGam">GAM</label>
          </div>
          <div class="form-check form-check-inline" v-if="settings.plotMode === 'CC'">
            <input class="form-check-input" name="plotType" type="radio" id="cbHgam" value="HGAM" v-model="settings.plotModel">
            <label class="form-check-label" for="cbHgam">HGAM</label>
          </div>

          <div class="form-check form-check-inline" v-if="settings.plotMode === 'CC'">
            <input class="form-check-input" name="plotType" type="radio" id="cbGamNB" value="GAMNB1" v-model="settings.plotModel">
            <label class="form-check-label" for="cbGamNB">GAMNB1</label>
          </div>
          <div class="form-check form-check-inline" v-if="settings.plotMode === 'CC'">
            <input class="form-check-input" name="plotType" type="radio" id="cbGamNB" value="GAMNB2" v-model="settings.plotModel">
            <label class="form-check-label" for="cbGamNB">GAMNB2</label>
          </div>
          <div class="form-check form-check-inline" v-if="settings.plotMode === 'CC'">
            <input class="form-check-input" name="plotType" type="radio" id="cbGamNB" value="GAMNB3" v-model="settings.plotModel">
            <label class="form-check-label" for="cbGamNB">GAMNB3</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" name="plotType" type="radio" id="cbTg" value="TG" v-model="settings.plotModel">
            <label class="form-check-label" for="cbTg">All</label>
          </div>
          <div class="form-check form-check-inline" v-if="settings.plotMode === 'CLASSIC'">
            <input class="form-check-input" name="plotType" type="radio" id="cbMa" value="MA" v-model="settings.plotModel">
            <label class="form-check-label" for="cbMa">Test</label>
          </div>

          <div v-if="settings.plotModel === 'TRIM' && settings.plotMode === 'CLASSIC'">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="cbSerialCorrelation" v-model="settings.trimSerialCorrelation">
              <label class="form-check-label" for="cbSerialCorrelation">
                {{ $t("plotOptions.more.serialCorrelation") }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="cbOverdispersion" v-model="settings.trimOverdispersion">
              <label class="form-check-label" for="cbOverdispersion">
                {{ $t("plotOptions.more.overdispersion") }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="cbTotals" v-model="settings.trimTotals">
              <label class="form-check-label" for="cbTotals">
                {{ $t("plotOptions.more.showTotals") }}
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="cbCovariateGeo" v-model="settings.trimCovariateGeo">
              <label class="form-check-label" for="cbCovariateGeo">
                Demo: Region As Covariate
              </label>
            </div>
            <div class="form-check">
              <select class="form-select form-select-sm" v-model="settings.trimModel">
                <option value="1">{{ $t("plotOptions.more.model1") }}</option>
                <option value="2">{{ $t("plotOptions.more.model2") }}</option>
                <option value="3">{{ $t("plotOptions.more.model3") }}</option>
              </select>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="cbChangepointsAll" v-model="settings.trimChangepointsAll">
              <label class="form-check-label" for="cbChangepointsAll">
                {{ $t("plotOptions.more.trimChangepointsAll") }}
              </label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="cbStepwise" v-model="settings.trimStepwise">
              <label class="form-check-label" for="cbStepwise">
                {{ $t("plotOptions.more.trimStepwise") }}
              </label>
            </div>
          </div>
        </div>

        <div class="col m-1">
          <h5>{{ $t("plotOptions.more.map") }}</h5>
          <div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="checkbox" value="" id="cbSquares" v-model="settings.showSquares">
              <label class="form-check-label" for="cbSquares">
                {{ $t("plotOptions.more.gridSquares") }}
              </label>
            </div>
            <div class="form-check mb-2">
              <input class="form-control" type="number" id="inputSquareSize" v-model="settings.squareSize" min="10" max="50" step="10">
              <label class="form-label" for="inputSquareSize">
                {{ $t("plotOptions.more.gridSize") }}
              </label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="checkbox" value="" id="cbDistribution" v-model="settings.showDistribution">
              <label class="form-check-label" for="cbDistribution">
                {{ $t("plotOptions.more.distributionLayer") }}
              </label>
            </div>
            <div class="form-check mb-2">
              <input class="form-check-input" type="checkbox" value="" id="cbMarkers" v-model="settings.showLocations">
              <label class="form-check-label" for="cbMarkers">
                {{ $t("plotOptions.more.locationMarkers") }}
              </label>
            </div>

            <h5 class="mt-5">Map Style (Temporary)</h5>
            <div class="form-check form-check-inline">
              <input class="form-check-input" name="mapLayer" type="radio" id="cbOSM" value="OSM" v-model="settings.mapLayer">
              <label class="form-check-label" for="cbOSM">OSM</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" name="mapLayer" type="radio" id="cbArcGIS" value="ArcGIS" v-model="settings.mapLayer">
              <label class="form-check-label" for="cbArcGIS">ArcGIS</label>
            </div>

            <br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" name="mapSiteShape" type="radio" id="cbSquare" value="square" v-model="settings.mapSiteShape">
              <label class="form-check-label" for="cbSquare">Square</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" name="mapSiteShape" type="radio" id="cbCircle" value="circle" v-model="settings.mapSiteShape">
              <label class="form-check-label" for="cbCircle">Circle</label>
            </div>

            <div class="m-3">
              <div class="row bg-primary text-light">
                <div class="col">Color</div>
                <div class="col">Border Width</div>
                <div class="col">Border Opacity</div>
                <div class="col">Fill Opacity</div>
              </div>
              <div class="row">
                <input class="col" type="color" v-model="settings.mapOptionsSite.color" id="cSiteColor">
                <input class="col" type="number" v-model="settings.mapOptionsSite.weight" min="0" max="2" step="0.5">
                <input class="col" type="number" v-model="settings.mapOptionsSite.opacity" min="0" max="1" step="0.1">
                <input class="col" type="number" v-model="settings.mapOptionsSite.fillOpacity" min="0" max="1" step="0.1">
              </div>
              <div class="row">
                <input class="col" type="color" v-model="settings.mapOptionsDistribution.color" id="cSiteColor">
                <input class="col" type="number" v-model="settings.mapOptionsDistribution.weight" min="0" max="2" step="0.5">
                <input class="col" type="number" v-model="settings.mapOptionsDistribution.opacity" min="0" max="1" step="0.1">
                <input class="col" type="number" v-model="settings.mapOptionsDistribution.fillOpacity" min="0" max="1" step="0.1">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center m-5">
        <button type="button" class="btn btn-outline-secondary btn-lg px-4" @click="emitChange()">{{ $t("plotOptions.more.apply") }}</button>
        <button type="button" class="btn btn-primary btn-lg px-4 gap-3"
                @click="emitChange()" data-bs-toggle="collapse" data-bs-target="#collapseMoreOptions">
          {{ $t("plotOptions.more.applyAndClose") }}
        </button>
        <button type="button" class="btn btn-outline-secondary btn-lg px-4" data-bs-toggle="collapse" data-bs-target="#collapseMoreOptions">{{ $t("plotOptions.more.close") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import PlotSettingsBase from "@/components/trends/settings/PlotSettingsBase.vue";
import {set} from "vue-demi";

export default defineComponent({
  extends: PlotSettingsBase,
  name: 'PlotSettings2',
  components: {},
  props: {
  },
  data: function (){
    return {

    }
  },
  // mounted: function() {
  //   // this.plotVisuals = new MyPlotVisuals();
  //   return;
  // },
  methods: {
    set,
    /**
     * to notify the parent container of changed settings.
     * @param data a description of what has changed (optional)
     */

    selectSpecies: function(species){
      this.settings.species  = species;
      this.emitChange({species: species});
    },
    selectBioGeo: function(bg){
      this.settings.selectedRegion = bg;
      this.emitChange({region: bg});
    },
    selectGeo1: function(geo1){
      this.settings.selectedRegion = geo1;
      this.emitChange({region: geo1});
    },
    selectGeoAdm: function(geoAdm){
      this.settings.selectedRegion = geoAdm;
      this.emitChange({region: geoAdm});
    },
    selectObservationType: function(t){
      this.settings.observationType  = t;
      this.emitChange({obsType: t});
    },
    selectYearStart: function(y){
      if (y >= this.settings.yearEnd){
        this.settings.yearEnd = y + 10;
      }
      this.settings.yearStart = y;
      this.emitChange({yearStart: this.settings.yearStart, yearEnd: this.settings.yearEnd});
    },
    selectYearEnd: function(y){
      if (y <= this.settings.yearStart){
        this.settings.yearStart = y - 10;
      }
      this.settings.yearEnd = y;
      this.emitChange({yearStart: this.settings.yearStart, yearEnd: this.settings.yearEnd});
    },
    changePlotMode: function(plotMode){
      const settings = this.settings;
      //  check if the plot type is valid
      if (settings.plotMode === plotMode) return;
      settings.plotMode = plotMode;
      if (plotMode === 'CC') {
        if (settings.plotModel !== 'HGAM' && settings.plotModel !== 'GAM' && settings.plotModel !== 'TRIM' && settings.plotModel !== 'TG') {
          settings.plotModel = 'TRIM';
        }
      }
      else {
        if (settings.plotModel === 'HGAM') {
          settings.plotModel = 'TRIM';
        }
      }
      this.emitModify({plotMode: plotMode, plotModel: settings.plotModel})
    },
    /** WTF? I cannot import the function from the utils.js file. If I do, the preview (npm run serve) does not work! */
    capitalizeAll: function(s){
      return s.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul.my-dropdown{
  z-index: 1002;
}
li.my-dropdown-item{
  cursor: pointer;
}

.highlight {
  font-weight: bold;
  position: relative;
  animation-name: example;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@keyframes example {
  from {left:-1px; color: #990000;}
  to {left:1px; color: #ff0000;}
}
</style>