import {ApiUtil} from "@/ApiCom";

class PossibleChoices{

}


class MyPlotSettings{
    ////
    // HELPER CONSTANTS
    ////
    // readonly $t: any;
    component: any;
    readonly allRegions: any;
    readonly mapRegion: any;

    constructor(component: any) {
        // this.$t = vueTranslator;
        this.component = component;
        this.allRegions = {id:-1, name: component.$t('plotOptions.allRegions')};
        this.mapRegion = {id:-2, name: component.$t('plotOptions.mapSection')};
    }

    ////
    //  DATA CONTAINERS
    ////
    /**
     * a map holding all the species with the id (Mmyo, Mnat ...) as key
     * comes from the server, and is stored as is
     */
    speciesDict: any = null;
    // speciesTitles: any = null;
    observationTypes: any = {};
    bioGeoRegions: any = {};
    geoRegions1: any = {};
    geoRegions2: any = {};
    geoRegions3: any = {};
    geoAdministrativeRegions: any = {};
    mapBounds: any = null;


    ////
    //  SELECTION / CURRENT SETTINGS
    ////
    /**
     * the currently selected species with all it's info.
     */
    species: any = null;
    /**
     * the currently selected observation type
     */
    observationType: any = null;
    /**
     * the currently selected region
     */
    selectedRegion: any = null;
    /**
     * the year that was selected as starting year
     */
    yearStart = 2000;
    /**
     * the year that was selected as the end year
     */
    yearEnd = 2020;

    ////
    //  POSSIBLE CHOICES / what's available for selection
    ////
    /**
     * the minimum year that's possible for the current options
     */
    yearMin = 2000;
    /**
     * the maximum year that's possible for the current options
     */
    yearMax = 2020;
    /**
     * filtered count types for the selection
     */
    availableCountTypes: any[] = [];
    /**
     * filtered observation types for the selection
     */
    availableObservationTypes: any[] = [];
    /**
     * filtered biogeo regions for the selection
     */
    availableBiogeo: any[] = [];
    /**
     * filtered geo1 regions for the selection
     */
    availableGeo1: any[] = [];
    avalaibleGeoAdm: any[] = [];


    ////
    // MORE OPTIONS
    ////
    plotMode: 'CLASSIC' | 'CC' = 'CLASSIC';
    plotModel = 'TRIM';
    showLocations = false;
    showSquares = true;
    squareSize = 20;
    showDistribution = true;
    filterDate = false;
    filterDateMonthStart = 12;
    filterDateMonthEnd = 2;
    filterDateIncludeNulls = false;
    trimModel = 2;
    trimSerialCorrelation = true;
    trimOverdispersion = true;
    trimTotals = false;
    trimCovariateGeo = false;
    trimChangepointsAll = false;
    trimStepwise = false;
    minCountsPerSite = 0;
    fillYearGaps = true;

    mapLayer: 'OSM' | 'ArcGIS' = 'OSM';
    mapOptionsSite = {color: '#000000', weight: 1, opacity: 1.0, fillOpacity: 0.4};
    mapOptionsDistribution = {color: '#ff0000', weight: 0.0, opacity: 1, fillOpacity: 0.4};
    mapSiteShape: 'circle' | 'square' = 'square';


    emitChange(data: any = undefined){
        // this.component.$emit('settingsChanged', data);
        console.warn("EMIT CHANGE NOT IMPLEMENTED")
    }
    /**
     * handles a server response and populates everything
     * @param json the server response
     */
    handleResponse(json: any){
        if (json.species) {
            ApiUtil.addKeyToMapItemsAsId(json.species);
            this.speciesDict = json.species;
            this.observationTypes = ApiUtil.listToMapWithIdAsKey(json.observationTypes);
            this.bioGeoRegions = ApiUtil.listToMapWithIdAsKey(json.biogeo);
            for (const idx in this.bioGeoRegions) this.bioGeoRegions[idx].type = 'b';
            this.geoAdministrativeRegions = ApiUtil.listToMapWithIdAsKey(json.geoadm);
            for (const idx in this.geoAdministrativeRegions) this.geoAdministrativeRegions[idx].type = 'a';
            this.geoRegions1 = ApiUtil.listToMapWithIdAsKey(json.geo1);
            this.geoRegions2 = ApiUtil.listToMapWithIdAsKey(json.geo2);
            this.geoRegions3 = ApiUtil.listToMapWithIdAsKey(json.geo3);
        }

        if (json.possible_choices){
            this.availableCountTypes = this.filterAvailable(this.speciesDict, json.possible_choices.count_types, 'latin');
            this.availableObservationTypes = this.filterAvailable(this.observationTypes, json.possible_choices.obs_types, 'name');
            this.availableBiogeo = this.filterAvailable(this.bioGeoRegions, json.possible_choices.biogeo_ids, 'name');
            this.availableGeo1 = this.filterAvailable(this.geoRegions1, json.possible_choices.geo1_ids, 'name');
            this.avalaibleGeoAdm = this.filterAvailable(this.geoAdministrativeRegions, json.possible_choices.geoadm_ids, 'name');
            this.yearMin = json.possible_choices.year_min;
            this.yearMax = json.possible_choices.year_max;
        }
        else{
            console.warn("No possible choices!");
        }


        if (! this.observationType){
            this.observationType = this.observationTypes['W'];
        }
        if (! this.selectedRegion){
            this.selectedRegion = this.allRegions;
        }

        if (json.plot_options){
            this.species = this.speciesDict[json.plot_options.species];
            this.observationType = this.observationTypes[json.plot_options.observationType];
            this.yearStart = json.plot_options.yearStart;
            this.yearEnd = json.plot_options.yearEnd;

            this.plotMode = json.plot_options.plotMode || 'CLASSIC';
            this.plotModel = json.plot_options.plotModel;
            this.showLocations = json.plot_options.showLocations;
            this.showSquares = json.plot_options.showSquares;
            this.squareSize = json.plot_options.squareSize;
            this.showDistribution = json.plot_options.showDistribution;
            this.filterDate = json.plot_options.filterDate;
            this.filterDateMonthStart = json.plot_options.filterDateMonthStart;
            this.filterDateMonthEnd = json.plot_options.filterDateMonthEnd;
            this.filterDateIncludeNulls = json.plot_options.filterDateIncludeNulls;
            this.trimModel = json.plot_options.trimModel;
            this.trimSerialCorrelation = json.plot_options.trimSerialCorrelation;
            this.trimOverdispersion = json.plot_options.trimOverdispersion;
            this.trimTotals = json.plot_options.trimTotals;
            this.trimCovariateGeo = json.plot_options.trimCovariateGeo;
            this.trimChangepointsAll = json.plot_options.trimChangepointsAll;
            this.trimStepwise = json.plot_options.trimStepwise;
            this.minCountsPerSite = json.plot_options.minCountsPerSite;
            this.fillYearGaps = json.plot_options.fillYearGaps;
        }
        else{
            console.warn("No options!");
        }

        if (! this.species){
            // alert("No species selected, using Mmyo as default");
            // this.species = this.speciesDict['Mmyo'];
            console.warn("No species selected.");
        }
        console.log("MyPlotSettings::handleResponse - species id is " + this.species?.id);
    }

    buildOptions() : any {
        const options: any = {
            plotMode: this.plotMode,
            plotModel: this.plotModel
        }
        if (this.species) options.species = this.species.id;
        if (this.observationType) options.observationType = this.observationType.id;
        if (this.selectedRegion){
            if (this.selectedRegion.id > 0){
                if (this.selectedRegion.type === 'b'){
                    options.biogeo = this.selectedRegion.id;
                }
                else{
                    options.geo1 = this.selectedRegion.id;
                }
            }
            else if (this.selectedRegion.id === -2){
                options.geoBounds = this.mapBounds;
            }
            else if (isNaN(this.selectedRegion.id)){
                if (this.selectedRegion.type === 'a'){
                    options.geoadm = this.selectedRegion.id;
                }
            }
        }
        options.yearStart = this.yearStart;
        options.yearEnd = this.yearEnd;

        //extra options
        if (this.canUseMoreOptions()) {
            if (this.filterDate) {
                options.filterDate = this.filterDate;
                options.filterDateStart = this.filterDateMonthStart;
                options.filterDateEnd = this.filterDateMonthEnd;
                options.filterDateIncludeNulls = this.filterDateIncludeNulls;
            }
            options.trimOverdispersion = this.trimOverdispersion;
            options.trimSerialCorrelation = this.trimSerialCorrelation;
            options.trimCovariateGeo = this.trimCovariateGeo;
            options.trimModel = this.trimModel;
            options.trimTotals = this.trimTotals;
            options.trimStepwise = this.trimStepwise;
            options.trimChangepointsAll = this.trimChangepointsAll;
            options.showLocations = this.showLocations;
            options.showSquares = this.showSquares;
            options.squareSize = this.squareSize;
            options.showDistribution = this.showDistribution;
            options.fillYearGaps = this.fillYearGaps;
            options.minCountsPerSite = this.minCountsPerSite;
        }
        return options;
    }

    /**
     * call this method, when the map bounds have changed
     * @param bounds leaflet bounds object
     * @returns {boolean} true if the map bounds have changed
     */
    updateMapBounds(bounds: any) : boolean{
        const oldBounds = this.mapBounds;
        this.mapBounds = bounds;
        if (bounds){
            this.selectedRegion = this.mapRegion;
            if (this.selectedRegion){ //} && this.selectedRegion.id === this.mapRegion.id) {
                return true;
            }
        }
        else{
            if (this.selectedRegion?.id === this.mapRegion?.id) {
                this.selectedRegion = this.allRegions;
            }
            if (oldBounds) return true;
        }
        return false;
    }

    /**
     * filters the available items from the map, and returns a sorted list
     * @param map the map containing all the items
     * @param available the list of the keys that should be filtered
     * @param sort the field that should be used for sorting
     * @private
     */
    private filterAvailable(map: any, available: any, sort: string){
        const list = [];
        for (const idx in available){
            const key = available[idx];
            const item = map[key];
            if (item){
                list.push(item);
            }
        }
        return list.sort(function (a, b){
            // return (a[sort].localeCompare(b[sort]));
            return (a[sort]?.localeCompare(b[sort] ?? '') ?? 0);
        });
    }

    private canUseMoreOptions(){
        const user = this.component.store.user;
        if (user){
            if (user.isAdmin){
                return true;
            }
        }
        return false;
    }

    yearsMin(){
        let v = Math.floor(this.yearMin / 10) * 10;
        const years = [];
        while (v < this.yearMax){
            years.push(v);
            v += 10;
        }
        return years;
    }

    yearsMax(){
        const addByModZero = (this.yearMax % 10) == 0 ? 0 : 1;
        const maxV = Math.floor(this.yearMax / 10 + addByModZero) * 10;
        let v = Math.floor(this.yearMin / 10 + 1) * 10;
        const years = [];
        while (v <= maxV){
            years.push(v);
            v += 10;
        }
        if (this.plotMode === 'CLASSIC') {
            if (years[years.length - 1] > this.yearMax) {
                years[years.length - 1] = this.yearMax;
            }
        }
        return years;
    }
}

export {MyPlotSettings}