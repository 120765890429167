<template>
  <div>
    <!-- The spinner -->
    <MySpinner :working="working" :fatalError="fatalError"></MySpinner>
    <div class="container">
      <!-- TOP BAR -->
      <PlotSettings2 ref="settings" @settingsChanged="settingsChanged" @settingsModify="settingsModify"></PlotSettings2>
      <!-- START OF ACTUAL CONTENT -->


      <div class="border rounded-bottom rounded-end mb-4 p-3" style="position:relative; top:-1px;  z-index: 0;" id="mainContainer">
        <div v-if="species">
          <div class="row">
            <div class="col-2 d-none d-xl-block">
              <SpeciesImageCarousel :species="species" :images="species.images" :bottom-bar="false" :indicators="false" mkey="1"></SpeciesImageCarousel>
            </div>

            <div class="col-xl-4 col-12 d-none d-xl-block">
              <h5 class="card-title">{{ capitalizeAll(species.name) }}</h5>
              <em class="">{{ species.latin }}</em>
              <div v-if="species">
                <a data-bs-toggle="collapse" href="#more" @click="toggleMoreInfo()">
                  {{ isMoreInfoOpen ? $t('trends.speciesInfoHide') : $t('trends.speciesInfoShow') }}
                </a>
              </div>
            </div>

            <div class="col-xl-3 col-12 mb-3">
              <h5 class="card-title">Trend</h5>
              <PlotErrorSmall :plot-error="plotError"></PlotErrorSmall>
              <div v-if="store.plotSettings.plotModel === 'TG'"></div>
              <TrendComponent v-else-if="overall && store.plotSettings.plotModel === 'TRIM'" class="text-start" :slope="overall.slope" :has-enough-data="hasEnoughData" :icon-size="64" margin-top="0"></TrendComponent>
              <TrendComponentGAM v-else-if="store.plotSettings.plotMode === 'CC'" :plot="plot" icon-size="16" icon-size-large="64" :has-enough-data="hasEnoughData"></TrendComponentGAM>
            </div>

            <div class="col-xl-3 col-12 mb-3">
              <h5 class="card-title">{{ $t('trends.numbers') }}</h5>
              <div v-if="nbCounts" :class="{'text-danger': !hasEnoughData}">
                {{$tc('trends.moreCounts1', {n: nbCounts})}}
                {{$tc('trends.moreCounts2', {n: contributors.length})}}
                {{$tc('trends.moreCounts3', {n: nbLocations})}}
              </div>
            </div>

            <div class="col-12 d-block d-xl-none mb-3">
              <h5 class="card-title">{{ species.name }}</h5>
              <em class="">{{ species.latin }}</em>
              <div v-if="species">
                <a data-bs-toggle="collapse" href="#more" @click="toggleMoreInfo()">
                  {{ isMoreInfoOpen ? $t('trends.speciesInfoHide') : $t('trends.speciesInfoShow') }}
                </a>
              </div>
            </div>

          </div>
        </div>

        <div v-if="species" class="collapse" id="more">
          <div v-if="species.counts" class="alert alert-info">
            LOL
          </div>
          <div v-for="(sub, subKey) in subSpecies" :key="subKey">
            <hr>
            <h1>{{sub.name}}</h1>
            <div class="row mt-4">
              <div class="col-xl-6 col-12">
                <div v-for="(desc, idx) in sub.desc" :key="'desc_' + idx">
                  <h6 v-if="idx!='description'">{{speciesTitles[idx]}}</h6>
                  <div class="mb-3 text-muted" v-html="desc"></div>
                </div>
              </div>
              <div class="col-xl-6 col-12 d-none d-xl-block">
                <div v-for="(img, idx) in sub.images" :key="idx">
                  <SpeciesImage :img="img" class="mb-3"></SpeciesImage>
                </div>
              </div>
            </div>
          </div>
          <SpeciesImageCarousel :species="species" :images="species.images" class="d-block d-xl-none" mkey="2"></SpeciesImageCarousel>
<!--          <div class="row mt-4">-->
<!--            <div class="col-xl-6 col-12">-->
<!--              <div v-for="(desc, idx) in species.desc" :key="'desc_' + idx">-->
<!--                <h6 v-if="idx!='description'">{{speciesTitles[idx]}}</h6>-->
<!--                <div class="mb-3 text-muted" v-html="desc"></div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-xl-6 col-12 d-none d-xl-block">-->
<!--              <div v-for="(img, idx) in speciesImages" :key="idx">-->
<!--                QWEW {{speciesImages}}-->
<!--                <SpeciesImage :img="img" class="mb-3"></SpeciesImage>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div v-else class="lead">
          <h4>{{ $t ('trends.intro.title') }}</h4>
          <div>{{ $t ('trends.intro.text1') }}</div>
          <div class="p-4 fs-4">
            <h4>{{ $t ('trends.intro.steps') }}</h4>

            <div class="d-flex flex-row align-items-center">
              <svg width="20" height="20" class="flex-shrink-0 me-2 text-primary"><use xlink:href="#bi-1-circle"/></svg>
              <span class="help1" @click="openSpeciesDropdown()" @mouseenter="highlight($event, 1, true)" @mouseleave="highlight($event, 1, false)">{{ $t ('trends.intro.step1') }}</span>
            </div>
            <div class="d-flex flex-row align-items-center dashed_underline">
              <svg width="20" height="20" class="flex-shrink-0 me-2 text-muted"><use xlink:href="#bi-2-circle"/></svg>
              <span class="help2" @mouseenter="highlight($event, 2, true)" @mouseleave="highlight($event, 2, false)">{{ $t ('trends.intro.step2') }}</span>
            </div>
            <div class="d-flex flex-row align-items-center dashed_underline">
              <svg width="20" height="20" class="flex-shrink-0 me-2 text-muted"><use xlink:href="#bi-3-circle"/></svg>
              <span class="help2" @mouseenter="highlight($event, 3, true)" @mouseleave="highlight($event, 3, false)">{{ $t ('trends.intro.step3') }}</span>
            </div>
          </div>

          <div v-if="store.plotSettings?.availableCountTypes?.length > 0">
            <SpeciesPreviewCarousel @selectSpecies="selectSpecies" :count-types="store.plotSettings.availableCountTypes"></SpeciesPreviewCarousel>
          </div>

          {{ $t ('trends.intro.text2') }}
        </div>


      </div>
      <div class="row">
        <PlotCard :plot="plot" :plot-error="plotError" plot-id="divPlot1" :working="working" class="col-xl-6 col-12 mb-4"></PlotCard>
        <TcMap :id-map="idMap" class="col-xl-6 col-12 mb-4"></TcMap>
        <!--        <TcContributors :contributors="contributors" class="col-12 mb-4"></TcContributors>-->
        <!--        <TcTrim :overall="overall" :summary="summary"></TcTrim>-->
        <TcMore :contributors="contributors" :overall="overall" :summary="summary" :timings="timings" :plot-error="plotError"
                @download="download" class="col-12 mb-4"></TcMore>
      </div>

    </div>
  </div>
</template>

<script>

import "leaflet/dist/leaflet.css"
import MySpinner from "@/components/trends/MySpinner.vue";
import TrendComponent from "@/components/trends/TrendComponent.vue";
import PlotsBase from "@/components/trends/PlotsBase.vue";
import PlotCard from "@/components/trends/PlotCard.vue";
import TcMap from "@/components/trends/cards/TcMap.vue";
import PlotSettings2 from "@/components/trends/settings/PlotSettings2.vue";
import SpeciesImage from "@/components/trends/cards/SpeciesImage.vue";
import SpeciesImageCarousel from "@/components/trends/SpeciesImageCarousel.vue";
import PlotErrorSmall from "@/components/trends/cards/PlotErrorSmall.vue";
import TcMore from "@/components/trends/cards/TcMore.vue";
import SpeciesPreviewCarousel from "@/components/trends/cards/SpeciesPreviewCarousel.vue";
import TrendComponentGAM from "@/components/trends/TrendComponentGAM.vue";


export default {
  extends: PlotsBase,
  name: 'PlotsThree',
  components: {
    TrendComponentGAM,
    SpeciesPreviewCarousel,
    TcMore,
    PlotErrorSmall,
    SpeciesImageCarousel,
    SpeciesImage,
    PlotSettings2,
    TcMap, PlotCard, TrendComponent, MySpinner
  },
  data: function (){
    return {
      isMoreInfoOpen: false,
    };
  },
  methods: {
    highlight(evt, idx, enabled){
      const elements = [];
      if (enabled) evt.target.classList.add('help-text');
      else evt.target.classList.remove('help-text');
      // console.log(evt);
      if (idx == 1){
        elements.push(document.getElementById('nlSpecies'));
      }
      else if (idx == 2){
        elements.push(document.getElementById('nlRegion'));
      }
      else if (idx == 3){
        elements.push(document.getElementById('nlTimespan1'));
        elements.push(document.getElementById('nlTimespan2'));
        elements.push(document.getElementById('nlTimespan3'));
        elements.push(document.getElementById('nlTimespan4'));
      }
      for (const el of elements){
        if (enabled)
          el.classList.add('highlight');
        else
          el.classList.remove('highlight');
      }
    },
    toggleMoreInfo: function () {
      this.isMoreInfoOpen = !this.isMoreInfoOpen;
    },
    getVisuals: function(){
      return this.$refs.settings.plotVisuals;
    },
    /** WTF? I cannot import the function from the utils.js file. If I do, the preview (npm run serve) does not work! */
    capitalizeAll: function(s){
      return s.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    },
    /**
     * Opens the species dropdown. This code is hacky, but it's the only known way to do it programmatically.
     */
    openSpeciesDropdown(){
      const elm = document.getElementById("nlSpecies");
      //  this disables the auto closing
      elm.setAttribute("data-bs-auto-close", "false");
      //  now open the dropdown
      // eslint-disable-next-line no-undef
      new bootstrap.Dropdown(elm).show();
      //  restore the original state
      elm.removeAttribute("data-bs-auto-close");
      //  recreate the dropdown to apply the original state.
      //  without the timeout it does not work (it's not opening).
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        new bootstrap.Dropdown(elm);
      }, 40);
    },
    /** called from the species preview carousel via events */
    selectSpecies: function (species){
      this.$refs.settings.selectSpecies(species);
    },
  },
  computed: {
    subSpecies(){
      if (this.species?.counts){
        const list = [];
        for (const sub of this.species.counts){
          list.push(this.store.speciesDict[sub]);
        }
        return list;
      }
      return [this.species];
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.help1{
  cursor: pointer;
}
.help2{
  cursor: help;
}
</style>
<style>

.help-text {
  font-weight: bold;
}


</style>