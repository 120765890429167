<script>
import {defineComponent} from 'vue';
import MyBase from "@/components/trends/MyBase.vue";
import {MyPlotSettings} from "@/components/trends/settings/MyPlotSettings";
import {MyPlotVisuals} from "@/MyD3";

export default defineComponent({
  extends: MyBase,
  name: 'PlotSettingsBase',
  components: {},
  props: {
  },
  data: function (){
    return {
      plotVisuals: this.createVisuals()
    }
  },
  methods: {
    emitChange: function(data = undefined){
      this.$emit('settingsChanged', data);
    },
    emitModify: function(data = undefined){
      this.$emit('settingsModify', data);
    },
    handleResponse: function (json){
      this.settings.handleResponse(json);
    },
    buildOptions: function (){
      return this.settings.buildOptions();
    },
    updateMapBounds: function(map){
      if (this.settings.updateMapBounds(map))
        this.emitChange();
    },
    createVisuals: function(){
      return new MyPlotVisuals();
    },
  },
  computed: {
    settings: function () {
      const settings = this.store.plotSettings;
      if (! settings){
        this._getStore().plotSettings = new MyPlotSettings(this);
      }
      return this.store.plotSettings;
    },
  }
});
</script>
